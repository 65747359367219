$red: #aa0000;

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.no-break {
    page-break-inside: avoid;
}