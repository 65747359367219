input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="checkbox"]:disabled,
input[type="radio"]:disabled,
button:disabled {
    cursor: not-allowed !important;
    opacity: .50
}

input[type="text"]:disabled,
textarea:disabled {
    cursor: not-allowed !important;
}

// removes the spinner circle for axios loading bar
#nprogress .spinner-icon {
    display: none;
}